import { createRouter, createWebHistory } from "vue-router"
import { myLocalStorage } from '@/utils/index'
import { ElMessage } from 'element-plus'
import { showToast } from 'vant';
import routes, {isMb} from "./routes"
const router = createRouter({
    history:createWebHistory(),
    routes
})

const list = routes[0].children.slice(1)
// const userInfo = {userId:1}
const whiteList = ['/Home', '/mobileHome', '/404', '/RingDetails', '/mobileRingDetails', '/WhiteList', '/mobileWhiteList', '/download']
const userInfo = myLocalStorage.get('userInfo', 'userInfo')

router.beforeEach((to, from, next) => {
    // console.log(whiteList.includes(to.path));
        if (whiteList.includes(to.path)) {
            // home页面登录或者不登录都可以进入
            next()
        } else {
            
            // 其他页面必须先登录
            if (userInfo && userInfo.userId) {
                // 正确路由正常跳转。 不是正确路由，跳转404页面
                const res = list.some(item => item.path === to.path)
                if (res) {
                    next()
                }else{
                    next('/404')
                }
            }else {
                next(`${isMb? '/mobileHome' :'/Home'}`)
                // next('/Home')
                setTimeout(() => {
                    const method = isMb ? showToast : ElMessage.warning
                    method('请先登录')
                }, 300);
            }
        }
  })

export default router