<template>
    <transition name="fade" mode="out-in">
        <div class="outer_box" v-if="showBanner" >
            <div class="cookie-banner">
                <div class="content">
                    {{ t('cookie.content') }}
                    <span class='provicy' @click="openPrivacy">{{ t('cookie.provicy') }}</span>.
                </div>
                <div class="btn_group">
                    <div class="btn btn_decline" @click="decline">{{ t('cookie.decline_btn') }}</div>
                    <div class="btn btn_accept" @click="acceptAll">{{ t('cookie.accept_btn') }}</div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useI18n } from "vue-i18n";
import cookies from 'js-cookie'

const { t, locale } = useI18n();

const showBanner = ref(false);
// const htmlContainer = ref(null);
// const htmlContent = ref('<button id="myButton">Click Me</button>');

const openPrivacy = () => {
    const privacy_url = `${locale.value == 'zh' ? '/privacypolicycn' : '/privacypolicy'}/index.html`;
    window.open(privacy_url);
}

// 在组件挂载时检查用户的 Cookie 同意状态
onMounted(() => {
    const consent = cookies.get('cookie_consent');
    showBanner.value = !consent;
});

// 用户接受所有 Cookie
const acceptAll = () => {
    cookies.set('cookie_consent', 'all');
    showBanner.value = false;

    window.location.reload();
};

// 用户拒绝特定的 Cookie
const decline = () => {
    cookies.set('cookie_consent', 'decline');
    cookies.remove();
    showBanner.value = false;

    window.location.reload();

};

</script>


<style scoped lang="scss">
    .outer_box {
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,0.8);
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9999;
    }
    .cookie-banner {
        width: 100vw;
        height: 354px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        left: 0;
        .content {
            width: 85vw;
            font-size: 20px;
            text-align: center;
            .provicy {
                color: #000;
                text-decoration: underline;
            }
        }
        .btn_group {
            width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            .btn {
                width: 294px;
                height: 48px;
                font-size: 24px;
                border-radius: 30px;
                border: 1px solid #000;
                text-align: center;
                line-height: 48px;
                margin-top: 20px;
                cursor: pointer;
            }
            .btn_accept {
                background: rgba(244, 241, 232, 1);
                border: none;
            }
            .btn_decline {
                background: #fff;
            }
        }
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
    