<script setup>
import { ref, reactive } from 'vue'
import { isEmailAvailable, myLocalStorage } from '@/utils/index'
import { loginApi, userWalletsApi } from '@/api/index'
import { showToast } from 'vant';
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const emit = defineEmits(['handleUserOption'])
// 切换表单
const handleClickTip = (type) =>{
 emit('handleUserOption', type)
}

const showPassword = ref(false)
const handleShowPassword = () =>{
    showPassword.value = !showPassword.value
}

const formData = reactive({
  email:'',
  password:'',
})
const isLogin = ref(false)
const handleLogin = () =>{
isLogin.value = true;
  if (!isEmailAvailable(formData.email)) {
    showToast(t('alert_tip.success_email'))
    return
  }

  loginApi(formData).then(async res => {
    isLogin.value=false;
    if (res.msg === 'success') {
        myLocalStorage.set('userInfo', 'userInfo', res.data )
        // 登录成功获取地址
        // const addressInfo =  await userWalletsApi({ userId: res.data.userId })
        // if (res.data) {
        //     myLocalStorage.set("userAddress", "userAddress", addressInfo)
        // }else {
        //     // 没有绑定地址 Todo
        // }
        // window.location.replace('http://dering\.io/')  
        window.location.reload();
      setTimeout(()=>{
        showToast(t('alert_tip.success_login'))
      }, 1000)
    }
  })
}

</script>
<template>
    <div class="accout">
        <input type="text" :placeholder="t('login_input.email_tip')"   v-model="formData.email">
    </div>
    <div class="password">
        <input :type="showPassword? 'text' : 'password'" :placeholder="t('login_input.password_tip')" v-model="formData.password">
        <div @click="handleShowPassword">
            <img v-if="!showPassword" class="password_icon" src="@/assets/svg/eye_colse.svg" alt="">
            <img v-else class="password_icon" src="@/assets/svg/eye_open.svg" alt="">
        </div>
    </div>
    <div class="forget_text" @click="handleClickTip('forget')">{{ t('login_input.forgot_tip') }}</div>
    <van-button class="login_btn" round color="#0500F9" :loading="isLogin" @click="handleLogin" :loading-text="t('login_input.btnLoad')" >{{t('login_input.btn')}}</van-button>
    <div class="accout_text">
        {{ t('login_input.register_tip') }} <span class="sign_up" @click="handleClickTip('register')">{{ t('login_input.register_tip2') }}</span>
    </div>
</template>
<style lang="scss" scoped>
    .forget_text, .accout_text{
        color: #FFFFFF;
        font-size: 11px;
        font-family: 'Gotham-Light';
        .sign_up{
            color: #0500F9;
            font-family: Gotham-Bold;
        }
    }
    .forget_text{
        margin-bottom: 39px;
    }
    .accout, .password{ 
        height: 46px;
        display: flex;
        align-items: center;
        input {
            width: 260px;
            height: 100%;
            border-radius: 23px;
            border: 1px solid #0400f9;
            outline: none;
            padding : 0 20px;
            background-color: transparent;
            font-size: 15px;
            color: #fff;
        }

        input::-ms-input-placeholder {
            text-align: center;
            font-size: 11px;
        }
        input::-webkit-input-placeholder {
            text-align: center;
            font-size: 11px;
        }
        input:focus{
            box-shadow: 0px 0px 15px 3px rgba(26,9,237,0.74);
            caret-color:#0400f9;
        }
    }

    .accout{
        margin-bottom: 29px;
    }
    .password{
        position: relative;
        margin-bottom: 15px;
        .password_icon{
            width: 25px;
            height: 20px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    .login_btn{
        width: 260px;
        height: 46px;
        color: #fff;
        font-size: 21px;
        font-family: Gotham-Light;
        text-align: center;
        line-height: 46px;
        margin-bottom: 50px;
    }
</style>